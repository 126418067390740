import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";

import Header from "./components/Header";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Footer from "./components/Footer";
function App() {
  return (
    <>
      <Header />
      <Hero />
      <Services />
      {/* <Testimonial /> */}
      {/* <CTA /> */}
      <Footer />
    </>
  );
}

export default App;
